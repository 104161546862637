@import '../../../styles/colors';
@import '../../../styles/typography';

.headerWrapper {
  width: 100%;
  height: 60px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 8px 24px 8px 32px;
  background-color: $base-white-1;
  border-bottom: 1px solid $base-gray-5;
  color: $base-gray-1;
  @include heading-h5;
}

.closeButton {
  color: $base-white-1;
}
