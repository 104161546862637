@mixin heading-h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 145%;
}

@mixin body-2 {
  font-size: 14px;
  font-weight: 700;
  line-height: 145%;
  text-transform: uppercase;
  letter-spacing: 0.08rem;
}

@mixin subtitle-2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 145%;
}

@mixin caption-2 {
  font-size: 12px;
  font-weight: 700;
  line-height: 145%;
}

@mixin caption-4 {
  font-size: 12px;
  font-weight: 400;
  line-height: 145%;
}

@mixin label-1 {
  font-size: 10px;
  font-weight: 400;
  line-height: 145%;
}

@mixin body-6 {
  font-weight: bold;
  font-size: 12px;
}
