$base-black-1: #333538;
$base-blue-3: #CCE5FF;
$base-blue-4: #F4FAFF;
$base-blue-5: #EDFDFF;
$base-blue-6: #809EAB;
$base-blue-7: #D5EEF1;
$base-gray-1: #333538;
$base-gray-3: #606875;
$base-gray-4: #A9B3BC;
$base-gray-5: #D9DDE1;
$base-gray-6: #E5EAF0;
$base-gray-7: #F2F2F2;
$base-gray-8: #969696;
$base-gray-9: #616161;
$base-green-1: #9AD27E;
$base-green-2: #B7DFA3;
$base-green-3: #E5F1E3;
$base-green-4: #FBFEFA;
$base-green-5: #F7FBF7;
$base-orange-1: #FAEAD2;
$base-pink-1: #F0DADE;
$base-white-1: #FFFFFF;
$base-white-2: #F4F8FB;
$base-white-3: #F9F9F9;
$base-yellow-1: #F1F518;

$accent-aqua-1: #46AABD;
$accent-blue-1: #465BBD;
$accent-brown-1: #B87251;
$accent-green-1: #6FBE46;
$accent-green-2: #5A964F;
$accent-orange-1: #E36414;
$accent-pink-1: #B7475D;
$accent-yellow-1: #E3E214;


$card-shadow-1: 0px 4px 4px rgba(50, 50, 71, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.06);
$card-shadow-2: 0px 16px 32px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.08);

$button-shadow-1: 0px 4px 8px rgba(50, 50, 71, 0.06), 0px 4px 4px rgba(50, 50, 71, 0.08);
